<script>
	import { onMount } from "svelte";
	// export let name;
	let inputRef = null;
	onMount(() => {
		inputRef.focus();
	});
</script>

<main class="section is-medium columns is-flex is-vcentered">
	<div class="container is-max-desktop has-text-centered">
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css">
                <link rel="apple-touch-icon" sizes="180x180" href="../favicon/apple-touch-icon.png">
                <link rel="icon" type="image/png" sizes="32x32" href="../favicon/favicon-32x32.png">
                <link rel="icon" type="image/png" sizes="16x16" href="../favicon/favicon-16x16.png">
                <link rel="manifest" href="../favicon/site.webmanifest">
                <link rel="mask-icon" href="../favicon/safari-pinned-tab.svg" color="#5bbad5">
                <meta name="msapplication-TileColor" content="#da532c">
                <meta name="theme-color" content="#ffffff">
		<img src="voa-persian.png" alt="VOA Farsi">
		<h1 class="title">Telegram Instant View Generator</h1>
		<form action="/api/readability" method="get" class="search-form">
			<div class="field has-addons">
				<p class="control is-expanded">
					<input
						name="url"
						type="url"
						required
						placeholder='Link to an "unreadable" article'
						bind:this={inputRef}
						class="input is-rounded has-text-centered search-box"
					/>
				</p>
				<p class="control">
					<input
						type="submit"
						value="Read"
						class="button is-link is-outlined is-rounded search-button"
					/>
				</p>
			</div>
		</form>
		<p>
			<small>
				The 
				<a
					href="https://github.com/gowee/readability-bot"
					target="_blank"
				>
				service
				</a>
				is powered by
				<a
					href="https://github.com/mozilla/readability"
					target="_blank"
				>
				Readability.js
				</a>
				and hosted on
				<a href="https://vercel.com/" target="_blank">Vercel</a>.
			</small>
		</p>
				<p>
			<small>
				Develop and customize for
				<strong><a title="VOA Farsi" href="https://ir.voanews.com/" target="_blank"><span style="color: #c41e22;">V</span><span style="color: #808080;">O</span></a><span style="color: #3c78b7;"><a title="VOA Farsi" href="https://ir.voanews.com/" target="_blank">A</a> <span style="color: #4a4a4a;">Farsi</span></span></strong>
				by
				<a
					href="https://twitter.com/radiojibi"
					target="_blank"
				>
				Ahmad Batebi
				</a>
				.
			</small>
		</p>
	</div>
</main>

<style>
	main {
		min-width: 100vw;
		min-height: 100vh;
		margin-top: -54px;
	}

	h1 {
  color: #34a9de;
  text-transform: inherit;
  font-size: 3.6em;
  font-weight: 100;
	}

	.seperator {
		padding: 0 3px;
	}

	.search-form .field {
		margin: 2rem 4rem;
	}

	@media (max-width: 768px) {
		.search-form .field {
			margin: 2rem 0rem;
		}
		
		h1 {
                        font-size: 1.2em !important;
               }
	}
</style>
